import { useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom'
import {
    Container, Row, Col, Button, Alert, Breadcrumb, Card, Form
} from 'react-bootstrap';
import { useAuth } from '../../utils/auth';
import axios from 'axios';

import shopifyBadge from './shopify-appstore-badg-black.png';

const Login = () => {
    const userInput = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const [shopifyLoginUrl, setShopifyLoginUrl] = useState();


    const fetchShopifyLoginUrl = async () => {
      try {
          const result = await axios.get(
              'shopify/htapp/login-url'
          );

          let nonce = new Date().getTime();
          sessionStorage.setItem("shopify-nonce", nonce)
          setShopifyLoginUrl(result.data + "--" + nonce);
      }
      catch (error) {
      }
  }
    
    useEffect(() => {

      // fetchShopifyLoginUrl();

  }, []);

    const redirectPath = location.state?.path || '/'

    const handleLogin = (e) => {
        e.preventDefault();
        
        // let userName = userInput.current.value;
        // if (userName) {
        //     auth.login(userName);
        //      navigate(redirectPath, { replace: true });
        // }
    };

    if (auth.user) {
        return <Navigate to='/' />
    }


    return (
        <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <Link to="/auth/sign-up"> <span className="link-primary">
                Sign Up
              </span></Link>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                ref={userInput}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control mt-1"
                placeholder="Enter password"
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button  onClick={handleLogin} className="btn btn-primary">
                Submit
              </button>
            </div>
            <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </form>

        {shopifyLoginUrl?<a href={shopifyLoginUrl} > Login with Shopify </a>:null}
      </div>
      
    )
};

export default Login;
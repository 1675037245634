import React, { useState,useEffect} from 'react';
import HTPage from '../components/HTPage';
import  Star  from '../layouts/auth/hellotabs-webpage-1.1.png';
import "../PageCss/About.css";
import HTSpinner from '../components/HTSpinner';
import axios from "axios";



export const About = () => {
    const [dashboard, setTestResult] = useState(false);
    const fetchDashboard = async () => {
      try {
        const result = await axios.get("htapp/dashboard");
  
        if (result.data) setTestResult(result.data);
      } catch (error) {}
    };
 
    useEffect(() => {
      fetchDashboard();
    }, []);
  
   
  

        
        return (
            
         
            
            <HTPage  title="About HelloTabs">
              
                {dashboard?(
                <>
           
        
        
            <div className="mainPage " style={{backgroundImage: `url(${Star})` }}>
            
                 <h3>HelloTabs 1.0</h3>
                 <p className='cardBodyAbout'>For more information please visit &nbsp; 
                     <a  href="https://www.hellotabs.com" target="_blank" >www.hellotabs.com </a></p>
                    
                 <p className='cardBodyAbout'>If you have any questions, thougths or ideas please fill out the  &nbsp; 
                 <a  href="https://www.hellotabs.com/contact-us.html" target="_blank">contact form</a></p>
               
                 </div>
                 </>
               )  : <HTSpinner message="Loading"/> } 
   </HTPage>
   
  
   
    
        );
    }


export default About;
import { useRef } from 'react'
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom'
import {
    Container, Row, Col, Button, Alert, Breadcrumb, Card, Form
} from 'react-bootstrap';
import { useAuth } from '../../utils/auth'


const SignUp = () => {


return <div className="Auth-form-container">
<form className="Auth-form">
  <div className="Auth-form-content">
    <h3 className="Auth-form-title">Sign In</h3>
    <div className="text-center">
      Already registered?{" "}
      <Link to='/auth/login'>
      <span className="link-primary" >
        Sign In
      </span></Link>
    </div>
    <div className="form-group mt-3">
      <label>Full Name</label>
      <input
        type="email"
        className="form-control mt-1"
        placeholder="e.g Jane Doe"
      />
    </div>
    <div className="form-group mt-3">
      <label>Email address</label>
      <input
        type="email"
        className="form-control mt-1"
        placeholder="Email Address"
      />
    </div>
    <div className="form-group mt-3">
      <label>Password</label>
      <input
        type="password"
        className="form-control mt-1"
        placeholder="Password"
      />
    </div>
    <div className="d-grid gap-2 mt-3">
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </div>
    <p className="text-center mt-2">
      Forgot <a href="#">password?</a>
    </p>
  </div>
</form>
</div>

}

export default SignUp;
import { useAuth } from '../../utils/auth';
import { useNavigate } from 'react-router-dom'
import logo from './ht-logo-2.png';
import HTReactIcon from "../../components/HTReactIcon";

export const AppHeader = () => {
  // Welcome {auth.user} 

  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await auth.logout();
    navigate('/auth/login', { search: "", replace: true });
  }

  return (

    <header className="navbar navbar-dark sticky-top  flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/"><img alt='logo' src={logo} height="28" /></a>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Eğer div yerine input yazarsak ve açarsak search barı kaldırınca bozulmalar meydana geliyor o yüzden input yerine div yazdık ve açık bıraktık */}

      <div id="main-search" className=" form-control-dark w-100 rounded-0 border-0" type="text" placeholder="Search" aria-label="Search"></div>

      <span className="navbar-text text-nowrap">
        <HTReactIcon
          name={"bs-shop-window"}

        ></HTReactIcon>{" "}
        {auth.session.accountName}
      </span>


      <div className="navbar-nav">

        <div className="nav-item text-nowrap">
          <a className="nav-link px-4" role='button' onClick={handleLogout}>Sign Out</a>
        </div>
      </div>
    </header>

  );

}

export default AppHeader;
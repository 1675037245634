import React from 'react';

import HTPage from '../components/HTPage';


function Projects() {
        return (
            <HTPage title="Projects">
project list
           </HTPage>
        );
    }


export default Projects;
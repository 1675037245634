
import React from 'react';
import HTReactIcon from '../components/HTReactIcon';
import HTPage from '../components/HTPage';
import { useState, useEffect } from 'react';
import axios from 'axios';
import "../PageCss/Home.css";
import "../PageCss/Products.css"
import HTSpinner from '../components/HTSpinner';




 const Products = () => {

    const [dashboard, setTestResult] = useState(false);
    
    const fetchDashboard = async () => {
        try {
            const result = await axios.get(
                'htapp/dashboard'
            );

            if(result.data)
                setTestResult(result.data);
        }
        catch (error) {
        }
    }
      
      useEffect(() => {
        fetchDashboard();
  
    }, []);


    
        return (
            <div>
                 <HTPage  title="Products">
         {dashboard?
        
            
           
          
           
           
    <div >
    
        <table className="table ">
          
          <tbody  >
            
        
          {dashboard.recentProducts.map((p)=>
          
                <tr   key={p.id} >   
                <td ><img alt='img' src={p.imageUrl} width="32" height="32"/></td>
                <td className='cardBodyAbout'><a  href={['https:/',dashboard.domain,'products',p.handle].join('/')} target='blank'  >{p.title}</a>
                <button className="onClickProducts"  onClick={event =>  window.location.href=['https:/',dashboard.domain,'products',p.handle].join('/')}><HTReactIcon   className="translate-middle-x" name={"bi-link-external"}/></button>
                
                 </td>
              
                

            
              
              
           
                
             

              
            </tr>
           
          )}
          
          
          
          </tbody>
          </table>
          </div>
     
 
    
      

            
          
       : <HTSpinner message="Loading"/>
         
      
         
         }
        </HTPage>

  
            </div>
        );
    }

export default Products;
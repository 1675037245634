import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

class HTSpinner extends Component {

    constructor(props) {
        super(props);
    }


    render(){return (
        <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <Spinner animation="border" role="status"></Spinner><br></br>{this.props.message}</div>


    )}

}

export default HTSpinner
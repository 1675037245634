import React from 'react';
import './App.css';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import AppHeader from './AppHeader';
import HTReactIcon from '../../components/HTReactIcon'

import { useAuth } from '../../utils/auth';

// import React, {useState, useRef, useReducer, useEffect, useMemo} from 'react';

//export const UserContext = React.createContext(null);
// // const Topbar = () => {
// //   const user = useContext(UserContext);

// //   // use `user` here
// // };

const HTMenu = [

  {
    title: "Dashboard",
    icon: "fi-home",
    link: "/",
  },

  {
    title: "Project",
    icon: "fi-file-text",
    link: "/projects/1",
  },

  {
    title: "Plan",
    icon: "fi-credit-card",
    link: "/plan/update",
  },
  // {
  //   title: "Products",
  //   icon: "im-dropbox",
  //   link: "/products",
  // },
 
  {
    title: "About",
    icon: "fi-info",
    link: "about",
  },
 



];



function App() {
  //  const [user, setUser] = useState(null);

  const auth = useAuth();  


  return (
	auth.isRedirect.length ? 
    <Navigate to={auth.isRedirect.pop()}/>:


    <>
      <AppHeader />

      <div className="container-fluid">
        <div className="row">
          <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3 sidebar-sticky">
              <ul className="nav flex-column">
                {
                  HTMenu.map((m,i) =>
                    <li  key={i} className="nav-item">
                      <NavLink to={m.link} className="nav-link">
                        <HTReactIcon name={m.icon} className="align-text-bottom feather" />
                        {m.title}
                      </NavLink>
                    </li>)
                }
              </ul>


              {/* Secondary Menu */}
              {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span>Projects</span>
                <a className="link-secondary" href="#" aria-label="Add a new report">
                  <HTReactIcon name="fi-plus-circle" className="align-text-bottom feather"/>
                </a>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <a className="nav-link" href="#">
                  <HTReactIcon name="fi-file-text" className="align-text-bottom feather"/>
                    Default Project
                  </a>
                </li>
              </ul> */}




            </div>
          </nav>


          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <Outlet />

          </main>
        </div>
      </div>

    </>
  );
}

export default App;

import { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import HTSpinner from '../components/HTSpinner';

const AuthContext = createContext(null);


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [session, setSession] = useState();
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [isRedirect, setIsRedirect] = useState([]);

  useEffect(() => {


    if (window.location.pathname.includes('auth/shopify')) {
      setIsLoadingSession(false);
      return;
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let internalRedirect = null;
    //console.log("params.xhdl: " + params.xhdl);
    //console.log("params.state: " + params.state);
    if (params.xhdl) {
      internalRedirect = params.xhdl.replaceAll('.', '/');
    }
    else if (params.state) {
      let state = params.state.split('--')[0];
      if (state.includes('xhdl.'))
        internalRedirect = state.replaceAll('xhdl', '').replaceAll('.', '/');
    }

    //console.log("internalRedirect: " + internalRedirect);
    if (internalRedirect) {
      if (window.location.pathname !== internalRedirect) {
        isRedirect.push(internalRedirect + window.location.search);
        setIsLoadingSession(false);
        return;
      }
    }


    let accessToken = sessionStorage.getItem("access_token");
    //console.log("Checking access token: " + accessToken);
    if (accessToken) {
      fetchSession(accessToken);
    }
    else {
      closeSession();
      setIsLoadingSession(false);
    }

  }, []);

  const fetchSession = async (accessToken) => {
    try {
      const result = await axios.get('auth/session/' + accessToken);

      if (result.data) {
        sessionStorage.setItem("access_token", accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        setSession(result.data);
        setUser("test-user");
        setIsLoadingSession(false);
      }
    }
    catch (error) {
      closeSession();
      setIsLoadingSession(false);
    }


  };

  const closeSession = () => {
    setUser(null);
    setSession(null);
    window.sessionStorage.removeItem("access_token");
    axios.defaults.headers.common['Authorization'] = null;
    if (window.location.pathname !== '/#/auth/login') {
      //console.log("closeSession to login");
      window.history.pushState({}, '', "/#/auth/login");
    }
  }

  const login = user => {

  }

  const logout = async () => {

    if (!session?.sessionId)
      closeSession();

    setIsLoadingSession(true);
    try {
      await axios.get('auth/session/' + session.sessionId + "/logout");
      setIsLoadingSession(false);
    }
    catch (error) {
      setIsLoadingSession(false);
    }
    closeSession();
  }

  return (
    <AuthContext.Provider value={{ session, user, login, logout, fetchSession, isLoadingSession, isRedirect }}>
      {
        isLoadingSession ?
          <HTSpinner message="Please wait..." /> : children
      }

    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';

import AppRoutes from './AppRoutes';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use((response) => response, (error) => {
  if(error.response.status == 401)
  {
    //console.log("AXIOS Log out!");
    window.history.pushState({},'',"/#/auth/login");
  }
  

  // whatever you want to do with the error
  throw error;
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode> // causes the components to be rendered twice in developement mode

    <AppRoutes />

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import './Auth.css';
import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import { AuthProvider } from '../../utils/auth';


function AuthLayout() {


    return (
        <div className='Auth'>
            <Outlet />
        </div>
    );
}


export default AuthLayout;

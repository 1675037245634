
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../utils/auth';



const ShopifyLogin = () => {

    const auth = useAuth();

    const initialData = {};
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(initialData);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const Status =
    {
        Success: 0,
        NotInstalled: 1,
        NotSubscribed: 2,
        AccessTokenInvalid: 3,
        NeedAppStore: 4
    }

    const params = {};

    for (let entry of searchParams.entries()) {
        var key = entry[0];
        var value = entry.length === 2 ? entry[1] : null;
        params[key] = value;
    }
    params['queryString'] = '' + searchParams;

    const doShopifyLogin = async (searchParams) => {
        setData(initialData);
        setIsLoading(true);
        setError(false);
        //console.log("search params: " + searchParams);

        try {
            const result = await axios.post(
                'shopify/htapp/login', params
            );

            setData(result.data);

            // Go to installation
            if (result.data.redirect) {
                if (result.data.status === Status.NeedAppStore) {
                    setError("Please install htapp from Shopify App Store");
                    setTimeout(() => { navigate(result.data.redirect, { replace: true }); }, 5000);

                }
                else {
                    let nonce = new Date().getTime();
                    sessionStorage.setItem("shopify-nonce", nonce)
                    window.location.href = result.data.redirect + "--" + nonce;
                }
            }

            // Go to subscription page
            if (result.data.status === Status.NotSubscribed) {
                navigate('/auth/shopify-subscribe/' + result.data.tempKey + '/' + result.data.productCount, { replace: true });
            }

            // New session created
            if (result.data.sessionId) {
                auth.fetchSession(result.data.sessionId).then(() => {

                    window.location.replace(process.env.REACT_APP_APP_URL); 
                // navigate({
                //     pathname: "/", //"process.env.REACT_APP_APP_URL",
                //     search: '',
                //     replace: true 
                //     });
                });
            }
        } catch (err) {
            setError(error);
        }
        setIsLoading(false);
    };


    useEffect(() => {

        //Verify nonce if state parameter exists
        let state = searchParams.get("state");
        if (state) {
            let nonce = state.split('--')[1];
            if (sessionStorage.getItem("shopify-nonce") !== nonce) {
                setError("Nonce invalid");
                return;
            }
        }

        doShopifyLogin(searchParams);

    }, []);


    
    return (<>
   
   

        <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" style={{ maxWidth: '700px' }}>
           
            <h1 className="display-4">Welcome to HelloTabs!</h1>
        </div>
        {/* <div>Search Params: {'' + decodeURI(searchParams)}</div> */}
        <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            {error && <div>{error}</div>}
            {isLoading ? (
                <>
                    <Spinner animation="border" role="status" />
                    <br />
                    {params.code ?
                        <span className='mx-3' >Installing...</span> :
                        <span className='mx-3' >Authorizing...</span>
                    }
                </>
            ) : (
                <ul>
                   
                </ul >
            )}
        </div>
    </>
    );
}



export default ShopifyLogin;
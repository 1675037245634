import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../utils/auth'

export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  return auth.session||auth.isRedirect.length?
    children
    :<Navigate to='/auth/login' options={{state:{ path: location.pathname }, replace:true, search:''}}/>
}

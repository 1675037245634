import React, { Component } from 'react';
import { FiPlusCircle, FiHome, FiFile, FiShoppingCart, FiUsers, FiPlus,
     FiFileText, FiMail,FiInfo, FiSave, FiUploadCloud, FiDollarSign, FiCreditCard } from 'react-icons/fi'; // feather icons
import {BiLinkExternal} from "react-icons/bi";
import {SlCalender} from "react-icons/sl";
import {RxAvatar} from "react-icons/rx";
import {AiOutlineReload} from"react-icons/ai";
import {GiProcessor, GiClick} from "react-icons/gi";
import {BsInfoSquare,  BsShopWindow} from "react-icons/bs";
import {ImDropbox} from "react-icons/im"



const icons = {
    "fi-plus-circle": FiPlusCircle,
    "fi-credit-card": FiCreditCard,
    "fi-dollar-sign": FiDollarSign,
    "fi-home": FiHome,
    "fi-file": FiFile,
    "fi-shopping-cart": FiShoppingCart,
    "fi-users": FiUsers,
    "fi-plus": FiPlus,
    "fi-file-text": FiFileText,
    "fi-mail": FiMail,
    "fi-info": FiInfo,
    "fi-save":FiSave,
    "fi-upload-cloud":FiUploadCloud,
    "bi-link-external": BiLinkExternal,
    "sl-calender": SlCalender,
    "ai-outline-reload":AiOutlineReload,
    "rx-avatar":RxAvatar,
    "gi-processor":GiProcessor,
    "bs-info-square":BsInfoSquare,
    "bs-shop-window":BsShopWindow,
    "gi-click" : GiClick,
    "im-dropbox": ImDropbox
};


class HTReactIcon extends Component {

    render() {

        const Icon = icons[this.props.name];

        return <Icon  {...this.props} />;
    }
}

export default HTReactIcon;
import React from "react";
import {  NavLink } from 'react-router-dom';
import HTReactIcon from "../components/HTReactIcon";
import HTPage from "../components/HTPage";
import { useState, useEffect } from "react";
import axios from "axios";
import "../PageCss/Home.css";
import HTSpinner from '../components/HTSpinner';



export const Home = () => {
  const [dashboard, setTestResult] = useState(false);
  const [deepLink, setDeepLink] = useState(null);
  const fetchDashboard = async () => {
    try {
      const result = await axios.get("htapp/dashboard");

      if (result.data){
         setTestResult(result.data);


          // https://shopify.dev/docs/apps/online-store/theme-app-extensions/extensions-framework#app-embed-block-deep-link-url-query-parameters
          // App embed block URL
          // https://<myshopifyDomain>/admin/themes/current/editor?context=apps&template=${template}&activateAppId={uuid}/{handle}
          // {uuid}	The ID of the theme app extension. Get from .env file of Shopify extension project
          // {handle} The filename of the block's Liquid file. For example, if the Liquid file is located at theme-app-extension/blocks/app-embed.liquid, then the {handle} is app-embed.

          // https://ui-dev-test.myshopify.com/admin/themes/current/editor?context=apps&activateAppId=52c056ee-e59a-410b-a8c8-686e6d418395/hellotabs-client-script
         let link = 'https://' 
            + result.data.shopDomain 
            +'/admin/themes/current/editor?context=apps&activateAppId=52c056ee-e59a-410b-a8c8-686e6d418395/hellotabs-client-script';
         setDeepLink(link);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  // let productCountProcess = (Math.floor(dashboard.productCountProcessed/dashboard.productCount*100)) ;

  const productCountProcess = () => {

    if (dashboard.productCount !== 0) {
        return "" + Math.floor(
            ((dashboard.productCountProcessed / dashboard.productCount) * 100 )
        ) + "%";
    }
    return null;
  };

  return (
    <HTPage title="Dashboard">
        
      {dashboard ? (
        
        <>
<div className="alert alert-primary " role="alert">
<h6>IMPORTANT</h6>
In order to make HelloTabs work for your Shopify store you need to activate <strong>HelloTabs Client Script</strong> for your current theme in the theme editor, from <strong>Theme Settings &gt; App embeds</strong><br/>

<a href={deepLink} target="_blank" className="alert-link">Click here</a> to activate the HelloTabs Client Script in theme editor. Don't forget to save the theme changes.
<br></br>For more information see the <a href="https://www.hellotabs.com/manual.html" target="_blank" className="alert-info">manual page</a>
   
          
        </div>

          <div className="">
            <div className="row mb-1 ">
              {/* <div className="col-md-6 col-xxl-3 my-2">
                <div className="card  shadow">
                  <div className=" cardBody ">
                    <div className="row ">
                      <div className="col-4 ">
                        <HTReactIcon
                          name={"sl-calender"}
                          className="icons  m-4"
                        ></HTReactIcon>
                      </div>
                      <div className="card-body col-8 text-center ">
                        <h4>Trial Days</h4>
                        <h5 className="mt-3">
                          {dashboard.trialRemaining}/{dashboard.trialDays}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}


<div className="col-lg-4  my-2 ">
                <div className="card  shadow ">
                  <div className="  cardBody ">
                    <div className="row ">
                      <div className="col-2 ">
                        <HTReactIcon
                          name={"gi-processor"}
                          className="icons "
                        ></HTReactIcon>
                      </div>
                      <div className="card-body col-10 text-center ">
                        <h4>Project Status</h4>

                        <h5 className="fontStyle">
                          {dashboard.projectEnabled?"Enabled":"Disabled"}

                        </h5>
                        {dashboard.projectEnabled?null:                        
                          <p className="fontStyle2 ">
                             <NavLink to="projects/1" className="nav-link">Go to <span className="text-primary">project</span> to enable</NavLink>
                        </p>
}
                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <div className="col-lg-4  my-2 ">
                <div className="card  shadow ">
                  <div className=" cardBody ">
                    <div className="row ">
                      <div className="col-2 ">
                        <HTReactIcon
                          name={"im-dropbox"}
                          className="icons "
                        ></HTReactIcon>
                      </div>
                      <div className="card-body col-10 text-center">
                        <h4 >Total Products</h4>
                        <h5 className="fontStyle">{dashboard.productCount}</h5>
                        {dashboard.plan.limits.productCount  
                          && dashboard.plan.limits.productCount < dashboard.productCount ?
                                                     
                          <p className="fontStyle2 ">
                             <NavLink to="plan/update" className="nav-link">Please consider upgrading your <span className="text-primary">plan</span></NavLink>
                        </p>:null
}
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-lg-4  my-2 ">
                <div className="card  shadow">
                  <div className=" cardBody ">
                    <div className="row ">
                      <div className="col-2 ">
                        <HTReactIcon
                          name={"bs-info-square"}
                          className="icons  "
                        ></HTReactIcon>
                      </div>
                      <div className="card-body col-10 text-center ">
                        <h4>Plan Info</h4>
                        <h5 className="fontStyle">{dashboard.plan.name}  </h5>
                        <p className="fontStyle2">Up to <strong>{
                            dashboard.plan.limits.productCount?
                              dashboard.plan.limits.productCount
                              :"Unlimited"}</strong> products
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </div>
          </div>




          <h4 className="mt-4 mb-3" style={{padding:"20px"}}>Recently Processed Items</h4>
          <div className="recentProductsScroll ">
            <table className="table ">
              <tbody>
                {dashboard.recentProducts.map((p) => (
                  <tr key={p.id}>
                    <td>
                      <img alt={p.title} src={p.imageUrl} width="32" height="32" />
                    </td>
                    <td>
                      <a
                        href={[
                          "https:/",
                          dashboard.domain,
                          "products",
                          p.handle,
                        ].join("/")}
                        target="blank"
                      >
                        {p.title}
                      </a>
                      <HTReactIcon
                        name={"bi-link-external"}
                        className="recentIcons"
                      ></HTReactIcon>{" "}
                    </td>

                    {/* <td>
                      <HTReactIcon name={"ai-outline-reload"} />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
        </>
      ) : <HTSpinner message="Loading"/>}
    </HTPage>
  );
};

export default Home;

import React, { Component } from "react";

class HTProjectState extends Component {
  constructor(props) {
    super(props);
    let data = this.props.statusData;
    this.state = {
      data: data,
      type: data.type,
      staticIcon: data.staticIcon,
      // Convert ms to seconds
      delay: data.delay / 1000,
      period: data.period / 1000,
      icons: data.icons,
      message: data.message,
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onStaticIconChange = this.onStaticIconChange.bind(this);
    this.onDelayChange = this.onDelayChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onIconsChange = this.onIconsChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);

    this.typeOptions = [
      {
        label: "None",
        value: 0,
        sub: "Favion is not changed",
      },
      {
        label: "Static Image",
        value: 1,
        sub: "Favion is replaced with a static image of your choice",
      },
      {
        label: "Blink",
        value: 2,
        sub: "A blinking favicon with the image of your choice",
      },
      {
        label: "Animate",
        value: 3,
        sub: "Animated favicon with the images of your choice",
      },
    ];

    this.staticIconOptions = [
      {
        label: "Main Product Image",
        value: 0,
      },
      {
        label: "Active Variant Image",
        value: 1,
      },
      {
        label: "Choose by Index",
        value: 2,
      },
    ];
  }

  onTypeChange(e) {
    // console.log("Type Changed " + e.target.value);
    let value = parseInt(e.target.value);
    this.setState({ type: value });
    this.props.updateCallback(this.props.status, "type", value);
  }

  onStaticIconChange(e) {
    // console.log("Static Changed " + e.target.value);
    let value = parseInt(e.target.value);
    this.setState({ staticIcon: value });
    this.props.updateCallback(this.props.status, "staticIcon", value);
  }

  onDelayChange(e) {
    // console.log("Delay Changed " + e.target.value);
    this.setState({ delay: e.target.value });
    this.props.updateCallback(
      this.props.status,
      "delay",
      e.target.value * 1000
    );
  }

  onPeriodChange(e) {
    //console.log("Period Changed " + e.target.value);
    this.setState({ period: e.target.value });
    this.props.updateCallback(
      this.props.status,
      "period",
      e.target.value * 1000
    );
  }

  onIconsChange(e) {
    // console.log("Icons Changed " + e.target.value);
    let value = e.target.value.trim();
    value = value ? value.split(",").map((v) => v.trim()) : [];
    this.setState({ icons: value });
    this.props.updateCallback(this.props.status, "icons", value);
  }

  onMessageChange(e) {
    //console.log("Message Changed " + e.target.value);
    this.setState({ message: e.target.value });
    this.props.updateCallback(this.props.status, "message", e.target.value);
  }

  render() {
    return (
      <div className="border-bottom  pt-3 pb-3 mb-3">
        {/* d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center   */}

        {/* TYPE */}
        <div className="mb-3">
          <label
            htmlFor={"staticSelect-" + this.props.status}
            className="form-label"
          >
            Favicon Effect
          </label>
          <select
            id={"staticSelect-" + this.props.status}
            className="form-select"
            aria-label="Effect Type"
            value={this.state.type}
            onChange={this.onTypeChange}
            data-show-subtext={true}
          >
            {this.typeOptions.map((option, i) => (
              <option key={i} value={option.value} data-subtext={option.sub}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {this.state.type == 0 ? null : (
          <>
            {/* STATIC ICON */}
            {!(this.state.type === 1 || this.state.type === 2) ? null : (
              <div className="mb-3">
                <label
                  htmlFor={"typeSelect-" + this.props.status}
                  className="form-label"
                >
                  {this.state.type === 1 ? "Static" : "Blink"} Image Selection
                </label>
                <select
                  id={"typeSelect-" + this.props.status}
                  className="form-select"
                  aria-label="Static Icon"
                  value={this.state.staticIcon}
                  onChange={this.onStaticIconChange}
                >
                  {this.staticIconOptions.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* ICONS */}
            {!(this.state.type > 2 || this.state.staticIcon === 2) ? null : (
              <div className="mb-3">
                <label
                  htmlFor={"icons-" + this.props.status}
                  className="form-label"
                >
                  {this.state.type === 1 || this.state.type === 2
                    ? "Image Index -- First image position is 0"
                    : "Image Indices -- First image position is 0. Separate numbers with comma(,) like 0,1,2 etc. Leave empty to use all images"}
                </label>
                <input
                  className="form-control"
                  id={"icons-" + this.props.status}
                  value={this.state.icons}
                  onChange={this.onIconsChange}
                ></input>
              </div>
            )}

            {/* DELAY */}
            <div className="mb-3">
              <label
                htmlFor={"delay-" + this.props.status}
                className="form-label"
              >
                Start after {this.state.delay} seconds
              </label>
              <input
                type="range"
                className="form-range"
                id={"delay-" + this.props.status}
                min="0"
                max="120"
                step="1"
                value={this.state.delay}
                onChange={this.onDelayChange}
              ></input>
            </div>

            {/* PERIOD */}
            {this.state.type <= 1 ? null : (
              <div className="mb-3">
                <label
                  htmlFor={"period-" + this.props.status}
                  className="form-label"
                >
                  Animation Period {this.state.period} seconds
                </label>
                <input
                  type="range"
                  className="form-range "
                  id={"period-" + this.props.status}
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={this.state.period}
                  onChange={this.onPeriodChange}
                ></input>
              
               <div className="d-flex justify-content-between">
               <label className="form-label">Faster</label>
               <label className="form-label" > Slower</label>
               </div>
           
              </div>
            )}

            {/* MESSAGE */}
            {/* <div className="mb-3">
                        <label htmlFor={"message-" + this.props.status} className="form-label">Message</label>
                        <input className="form-control" id={"message-" + this.props.status} value={this.state.message} onChange={this.onMessageChange}></input >
                    </div> */}
          </>
        )}
      </div>
    );
  }
}

export default HTProjectState;

import helloTabsLogo from "./hellotabs-logo-1-10.svg";
import shopifyBadge from "./shopify-appstore-badg-black.png";
import { useNavigate } from "react-router-dom";

const ShopifyOnly = () => {
  const navigate = useNavigate();

  function testLogin() {
    navigate({
      pathname: "/auth/shopify-login",
      search: "?testLogin=" + process.env.REACT_APP_TEST_STORE,
    });
  }

  return (
    <>
      <div
        className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "700px" }}
      >
        <img src={helloTabsLogo} height="90" />
        <br />

        {/* <h1 className="display-4">Welcome to Hellotabs!</h1> */}
      </div>
      {/* <div>Search Params: {'' + decodeURI(searchParams)}</div> */}
      <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h3 className="text-center mt-2">
          HelloTabs is only available on the Shopify App Store
        </h3>
        <p className="my-5">
          <a href="https://apps.shopify.com/">
            <img src={shopifyBadge} height="90" />
          </a>
        </p>

        <p>
          If you have already installed it, please open it from your Shopify
          store admin panel
        </p>

        {process.env.NODE_ENV === "development" ? (
          <button onClick={testLogin}>Test Login</button>
        ) : null}
      </div>
    </>
  );
};
export default ShopifyOnly;

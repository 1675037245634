
import React, { Component } from 'react';
import HTReactIcon from './HTReactIcon';

class HTPage extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);

    }

    onClick(groupId, buttonId) {
        let group = this.props.buttons[groupId];
        if(buttonId === 0)
            group.main.callback();
        else
            group.dropdown[buttonId -1].callback();
    }

    render() {

        return <>
            {/* Header */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <div>
                <h1 className="h2">{this.props.title}</h1>
                <small class="text-body-secondary">{this.props.subTitle}</small>
                </div>
                {this.props.buttons?.length ?
                    <div className="btn-toolbar mb-2 mb-md-0">
                        {this.props.buttons.map((b, i) =>
                            <div key={i} className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.onClick(i, 0)}>
                                    <HTReactIcon name={b.main.icon} className="align-text-bottom feather" /> {b.main.title}</button>

                                {b.dropdown?.length ? <>
                                    <button type="button" className="btn  btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul className="dropdown-menu">
                                        {b.dropdown.map((d, id) =>
                                            <li key={id}><a className="dropdown-item" role="button" onClick={() => this.onClick(i, id + 1)}>
                                                <HTReactIcon name={d.icon} className="align-text-bottom feather" /> {d.title}</a></li>)}
                                    </ul> </> : null}
                            </div>)}

                    </div> : null}

            </div>
            {this.props.children}
        </>
    }
}

export default HTPage;
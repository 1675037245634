import React, { Component } from 'react';

class NoMatch extends Component {
    render() {
        return (
            <div>
                Ooops! This is a 404
            </div>
        );
    }
}

export default NoMatch;
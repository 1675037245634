import React, { Component } from 'react';
import bootstrap from 'bootstrap'; // DON'T REMOVE THIS LINE
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';  // useMatch, useResolvedPath

import { AuthProvider } from './utils/auth';
import { RequireAuth } from './layouts/auth/RequireAuth';

import App from './layouts/app/AppLayout';
import AuthLayout from './layouts/auth/AuthLayout';

import Project from './pages/Project'
import Login from './pages/auth/Login'
import SignUp from './pages/auth/SignUp';
import Projects from './pages/Projects'
import Home from './pages/Home'
import About from './pages/About'
import Products from './pages/Products'

import NoMatch from './pages/NoMatch'
import ShopifyLogin from './pages/auth/ShopifyLogin';
import ShopifySubscribe from './pages/auth/ShopifySubscribe';
import ShopifyOnly from './pages/auth/ShopifyOnly';

import 'react-notifications/lib/notifications.css';
import {
  NotificationContainer,
} from 'react-notifications';

class AppRoutes extends Component {

  render() {
    
    let loginEnabled = process.env.REACT_APP_ENABLE_LOGIN;
    let signupEnabled = process.env.REACT_APP_ENABLE_SIGNUP;


    return (
      <AuthProvider>
         <NotificationContainer />
        <HashRouter>
          <Routes>

            <Route path="/" element={<RequireAuth><App /></RequireAuth>}>
              <Route index element={<Home />} />
              <Route path="projects" >
                <Route index element={<Projects />} />
                <Route path=":projectId" element={<Project />} />
              </Route>
              <Route path="products" element={<Products />} />
              <Route path="plan/:key" element={<ShopifySubscribe />} />
              <Route path="about" element={<About />} />
              <Route path="*" element={<NoMatch />} />
            </Route>


            <Route path="/auth/" component={<AuthLayout />}>
              {/*<Route path="lock" component={LockedScreen} />*/}
              {/*loginEnabled?<Route index element={<Login />}/>:null*/}
              {signupEnabled? <Route path="sign-up" element={<SignUp />} />:null}
              
              <Route path="login" element={loginEnabled?<Login/>:<ShopifyOnly/>} />
               
              
              <Route path="shopify-login" element={<ShopifyLogin />} />

              <Route path="shopify-subscribe/:key" element={<ShopifySubscribe />} />
              <Route path="shopify-subscribe/:key/:productCount" element={<ShopifySubscribe />} />
            </Route>


          </Routes>
        </HashRouter>
      </AuthProvider>
    );
  }
}

export default AppRoutes;
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

import HTPage from '../components/HTPage';
import HTSpinner from '../components/HTSpinner';
import HTProjectState from '../components/HTProjectState';

const defaultProjectData =
{
    id: 1,
    name: "My Favicon Project",
    enabled: true,
    effects: {
        visible:
        {
            type: 1,
            delay: 1000,
            period: 500,
            icons: [],
            staticIcon: 0,
            message: "",
        },

        hidden:
        {
            type: 2,
            delay: 3000,
            period: 500,
            icons: [],
            staticIcon: 0,
            message: "",
        }
    }
}


function Project() {
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isChanged, setIsChanged] = useState(false);
    const [projectData, setProjectData] = useState();


    const fetchProject = async () => {
        try {
            const result = await axios.get(
                'htapp/projects/' + projectId
            );

            setIsLoading(false);
            setProjectData(result.data);
        }
        catch (error) {
            setIsLoading(false);
            if (error.response.status === 404)
                setProjectData(defaultProjectData)
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchProject();
    }, []);



    const updateStatusData = (status, key, value) => {
        projectData.effects[status][key] = value;
        setIsChanged(true);
    }

    const updateEnabled = (e) => {
        setProjectData({ ...projectData, enabled: e.target.checked });
        setIsChanged(true);
    }

    const publish = async () => {
        setIsLoading(true);
        try {
            const result = await axios.post(
                'htapp/projects/' + projectId, projectData
            );

            NotificationManager.info(
                'Please wait a couple of minutes for the changes to take effect',
                "Successfuly published");

            setIsChanged(false);
        }
        catch (error) {
            NotificationManager.error(
                'Please try to re-login',
                "Publish failed");
        }
        finally {
            setIsLoading(false);
        }
    }
    const save = () => {
        alert(JSON.stringify(projectData));

    }

    const buttons = [{
        main: { title: (isChanged?"! ":"") + "Publish", icon: "fi-upload-cloud", callback: publish },
        //  dropdown: [{ title: "Save Only", icon: "fi-save", callback: save }]
    }];

    return (
        <>
            <HTPage title={projectData ? projectData.name + (isChanged ? '*' : '') : ""} 
                    subTitle="Click Publish button on the upper right to apply your project changes &#8594;"
                    buttons={projectData ? buttons : null}>


                {isLoading || !projectData ? <HTSpinner message="Loading..." /> : <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                            value={projectData.enabled} onChange={updateEnabled} defaultChecked={projectData.enabled} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enable Project</label>
                    </div>


                    <h4 className='my-3'>Browser Tab Effects</h4>


                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="visible-tab" data-bs-toggle="tab" data-bs-target="#visible-tab-pane" type="button" role="tab" aria-controls="visible-tab-pane" aria-selected="true">Visible/Active Browser Tab</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="hidden-tab" data-bs-toggle="tab" data-bs-target="#hidden-tab-pane" type="button" role="tab" aria-controls="hidden-tab-pane" aria-selected="false">Hidden/Inactive Browser Tab</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">


                        <div className="tab-pane fade show active" id="visible-tab-pane" role="tabpanel" aria-labelledby="visible-tab" tabIndex="0">

                            <div className="alert alert-primary mt-3 " role="alert">
                                Favicon settings for <strong>active/visible</strong> browser tabs.<br/>
                                These settings are applied when your customers are actively browsing your products.
                            </div>

                            <HTProjectState status="visible" statusData={projectData.effects.visible} updateCallback={updateStatusData} />

                        </div>
                        <div className="tab-pane fade" id="hidden-tab-pane" role="tabpanel" aria-labelledby="hidden-tab" tabIndex="0">

                            <div className="alert alert-primary mt-3 " role="alert">
                                Favicon settings for <strong>inactive/hidden</strong> browser tabs.<br/>
                                These settings are applied when your customers are viewing other browser tabs and the tab containing your site is no longer visible.<br/>
                                You can use these settings to draw the attention of the customers back to your site by applying dynamic favicons.
                            </div>

                            <HTProjectState status="hidden" statusData={projectData.effects.hidden} updateCallback={updateStatusData} />

                        </div>
                    </div>
                </>}

            </HTPage>
        </>
    );

}


export default Project;
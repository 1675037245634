import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation, Navigate, Link, useParams, useSearchParams } from 'react-router-dom';
import {
    Container, Row, Col, Button, Alert, Breadcrumb, Card, CardGroup, Form, Spinner
} from 'react-bootstrap';
import { useAuth } from '../../utils/auth';
import axios from 'axios';

import HTSpinner from '../../components/HTSpinner';


const ShopifySubscribe = (props) => {
    let { key, productCount } = useParams();
    const [plans, setPlans] = useState();
    const [plan, setPlan] = useState();
    const [planResult, setPlanResult] = useState(null);
    const [plansLoading, setPlansLoading] = useState(false);
    const [dashboardLoading, setDashboardLoading] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [error, setIsError] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const auth = useAuth();
    
    const [dashboard, setDashboardData] = useState(false);

    let chargeId = searchParams.get("charge_id");

    const fetchPlans = async () => {
        try {
            const result = await axios.get(
                'shopify/htapp/plans'
            );

            setPlansLoading(false);
            setPlans(result.data);

        }
        catch (error) {
        }
    }

    const fetchDashboard = async () => {
      try {
        const result = await axios.get("htapp/dashboard");
  
        if (result.data) setDashboardData(result.data);
        setDashboardLoading(false);
      } catch (error) {setDashboardLoading(false);}
    };
  



    useEffect(() => {
        setPlansLoading(true);

        if(key == 'update' && auth.session)
        {
            setDashboardLoading(true);
            fetchDashboard();
        }

        if (chargeId) {
            activatePlan(chargeId);
        }
        else {
            fetchPlans();
        }

    }, []);


    const activatePlan = async () => {
        try {
            const result = await axios.post(
                'shopify/htapp/subscription/' + key + '/activate/' + chargeId
            );

            setPlansLoading(false);
            setPlanResult(result.data);

            // New session created as a result of subscription
            if (result.data.sessionId)
                auth.fetchSession(result.data.sessionId).then(() => {

                    // Back to home
                    setTimeout(() => { 
                        window.location.replace(process.env.REACT_APP_APP_URL);  
                    //    navigate('/', { replace: true }); 
                    
                    
                    }, 2000);
                });



        }
        catch (error) {
            setIsError(error.message);
            setPlansLoading(false);
        }
    }

    const subscribePlan = async (p) => {
        setIsError(false);
        setButtonsDisabled(true);
        setPlansLoading(true);
        setPlan(p);
        try {
            //Updates work on active session
            if(key == 'update')
                key = "s_"+auth.session.sessionId;

            const result = await axios.post(
                'shopify/htapp/subscription/' + key + '/create/' + p.id
            );

            setPlansLoading(false);
            setPlanResult(result.data);

            if (result.data.redirectUrl)
                window.location.href = result.data.redirectUrl;

        } catch (error) {
            setIsError(error.message);
            setPlansLoading(false);
            setButtonsDisabled(false);
        }
    };


    let planMarked = false;
    function markPlan()
    {
        planMarked = true;
        return "bg-primary";
    }

    return <>
        <div className=" px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" style={{ maxWidth: '700px' }}>
            {key == 'update'?
            <h1 className="display-4">Change Plan</h1>
            :<h1 className="display-4">Thanks for installing HelloTabs!</h1>
            }

            {planResult?.message || chargeId ? null : <p className="lead">Please choose a plan that suits your needs</p>}
            {plan?.name ? <h3>Subscribing to {plan?.name}...</h3> : null}
            {!planResult?.message && chargeId ? <h3>Activating...</h3> : null}
            {plansLoading||dashboardLoading ? <HTSpinner message="Loading..." />: null}
            {error ? <p>Error {error} </p> : null}
            {planResult ? <h3>{planResult.message}</h3> : null}
        </div>
        {plansLoading||dashboardLoading ? null:
        <Container style={{ maxWidth: '960px' }}>
            <Row>
                {plans ? plans.map((p, i) => <Col key={i}>
                    <Card border="grey" style={{ minWidth: '10rem', minHeight: '20rem' }} 
                        className="text-center  my-4 shadow-sm" >
                            
                        <Card.Header className=
                        {!planMarked && p.limits.productCount >productCount ? markPlan():null}><h4 className="my-0 font-weight-normal">{p.name}</h4></Card.Header>


                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body className=" d-flex flex-column">
                        <Card.Title as='h2'>${p.price}<small className="text-muted">/ mo</small></Card.Title>
                        {p.trialDays ? <Card.Subtitle><small className="text-muted">{p.trialDays} days free trial</small></Card.Subtitle> : null}

                        <ul className="list-unstyled mt-3 mb-4">
                            <li><Card.Text>{p.highlight}</Card.Text></li>
                            <li>{p.limits.productCount ? <Card.Text>Up to <strong>{p.limits.productCount}</strong> products</Card.Text> : <Card.Text><strong>Unlimited </strong>Products</Card.Text>}</li>
                            {p.bulletPoints.map((bp, i2) => <li key={i2}><Card.Text>{bp}</Card.Text></li>)}
                        </ul>


{dashboard&& dashboard.plan.id == p.id?
    <Button variant="success" className="mt-auto" disabled={true}>Active Plan</Button>
    : <Button variant="primary" className="mt-auto" onClick={() => subscribePlan(p)} disabled={buttonsDisabled}>Subscribe</Button>
}
                        
                    </Card.Body>
                </Card></Col>) : null}
            </Row>
            {productCount>0 || dashboard?.productCount>0?<Row>You currently have {productCount??dashboard?.productCount} active products. 
            Please select your plan accordingly.</Row>:null}

        </Container>}


    </>

}

export default ShopifySubscribe;